<template>
  <div :id="id" :class="customClass"></div>
</template>

<script>
import * as d3 from "d3";
import $ from "jquery";
export default {
  props: ["id", "customClass"],
  data() {
    return {
      svg: null,
      yScale: null,
      yDomain: [],
      ease: "easeBack",
      finalPoints: [],
      initialPoints: [],
    };
  },
  methods: {
    async playOut() {
      const that = this;

      if (that.effect == "leftIn") {
        this.svg
          .selectAll(`.axis`)
          .transition()
          .delay((d, i) => (that.arr.length - 1 - i) * that.delay)
          .duration(that.duration)
          .attr("points", (d, i) => that.initialPointsArr[i]);

        this.svg
          .selectAll(`.text`)
          .transition()
          .delay((d, i) => (that.arr.length - 1 - i) * that.delay)
          .duration(that.duration)
          .attr("opacity", 0);
      } else if (that.effect == "bothIn") {
        this.svg.transition().duration(400).style("opacity", "0");
      } else {
        this.svg.transition().duration(400).style("opacity", "0");
      }
    },
    async init() {
      await this.getSettings();
    },
    getSettings() {
      return new Promise((resolve, reject) => {
        this.$nextTick(() => {
          resolve();
        });
      });
    },
    //核心库
    ratioBarChart(
      data,
      {
        x = (d, i) => i, // given d in data, returns the (ordinal) x-value
        // y = (d) => d, // given d in data, returns the (quantitative) y-value
        // z = () => 1,
        marginTop = 40, // the top margin, in pixels
        marginRight = 0, // the right margin, in pixels
        marginBottom = 30, // the bottom margin, in pixels
        marginLeft = 40, // the left margin, in pixels
        width = 640, // the outer width of the chart, in pixels
        height = 400, // the outer height of the chart, in pixels
        xDomain, // an array of (ordinal) x-values
        xRange = [marginLeft, width - marginRight], // [left, right]
        yDomain, // [ymin, ymax]
        yRange = [height - marginBottom, marginTop], // [bottom, top]
        zDomain, // array of z-values
        zPadding = 0.05,
        xPadding = 0.1, // 柱子的比例
        duration = 400, //动画持续时长
        delay = 100, //元素之间间隔时长
        colors = ["red", "gray", "green"],
        angle = 20, //斜角偏移量
        space = 10, //默认间隙
        showText = true,
        isMin = false, //是否固定过小值的宽度
        textOffset = "bottom", //字体默认显示在下方
        effect = "bothIn", //默认两边出现
      } = {}
    ) {
      const that = this;
      this.duration = duration;
      this.effect = effect;
      this.delay = delay;
      const svg = d3
        .create("svg")
        .attr("width", width)
        .attr("height", height)
        .attr("viewBox", [0, 0, width, height])
        .attr("style", "max-width: 100%; height: auto; height: intrinsic;");
      this.svg = svg;

      space = data.length > 3 ? 0 : space; //若是数据数量大于3,默认没有间隙
      const arr = data.map((item) => item.value);
      this.arr = arr;
      let allNum = arr.reduce((prev, curr) => prev + curr, 0);
      const allLength = xRange[1] - xRange[0];
      if (isMin || typeof isMin == "number") {
        const value = typeof isMin == "number" ? isMin : 0.05;
        for (let i = 0; i < arr.length; i++) {
          let item = arr[i];
          if (item < allNum * value) {
            arr[i] = allNum * value;
          }
        }
        allNum = arr.reduce((prev, curr) => prev + curr, 0);
      }
      //   console.log(minArr);
      if (xDomain === undefined) xDomain = [0, allNum];

      const barHeight = yRange[0] - yRange[1];
      const xScale = d3.scaleLinear(xDomain, xRange);

      const points = getFinalPoints(arr);
      const initialPoints = getInitialPoints(arr);
      this.initialPointsArr = initialPoints;

      const axes = svg
        .append("g")
        // .attr("class", "axes")
        .selectAll("axes")
        .data(points);
      //   console.log(points);
      axes
        .enter()
        .append("polygon")
        .attr("class", (d, i) => `axis axis${i}`)
        .merge(axes)
        .attr("points", (d, i) => initialPoints[i])
        .attr("fill", "transparent")
        .attr("fill", (d, i) => colors[i])
        .transition()
        .delay((d, i) => {
          if (effect == "bothIn") {
            let delta = i < arr.length / 2 ? i : arr.length - 1 - i;
            return delta * delay;
          } else if (effect == "leftIn") {
            return i * delay;
          }
        })
        .duration(duration)
        .attr("points", (d) => d);

      //是否展示字
      if (showText) {
        const textGroup = svg
          .append("g")
          .attr("class", "textGroup")
          .attr("text-anchor", "start")
          .attr("font-family", "sans-serif")
          .attr("font-size", 12)
          .selectAll("text")
          .data(data)
          .join("text")
          .attr("text-anchor", (d, i) => {
            if (i == 0) {
              return "start";
            } else if (i == data.length - 1) {
              return "end";
            } else {
              return "middle";
            }
          })
          .attr("class", "text")
          .text((d, i) => (d.showLabel ? d.label : ""))
          .attr("x", (d, i) => {
            if (i == 0) {
              return marginLeft;
            } else if (i == data.length - 1) {
              return xRange[1];
            } else {
              return (that.finalPoints[i].a[0] + that.finalPoints[i].b[0]) / 2;
            }
          })
          .attr("y", (d, i) => {
            return textOffset == "bottom" ? yRange[0] + 5 : marginTop - 5;
          })
          .attr("opacity", 0)
          .attr("fill", (d, i) => colors[i])
          .attr("dy", (d, i) => {
            return textOffset == "bottom" ? "0.7em" : "-0.1em";
          });

        textGroup
          .transition()
          .delay((d, i) => {
            if (effect == "bothIn") {
              let delta = i < arr.length / 2 ? i : arr.length - 1 - i;
              return delta * delay;
            } else if (effect == "leftIn") {
              return i * delay;
            }
          })
          .duration(duration)
          .attr("opacity", 1);
        this.textGroup = textGroup;
      }
      $("#" + this.id).html(svg.node());

      function getFinalPoints(arr) {
        //   获取最终点
        const list = [];
        const l = arr.length;
        for (let i = 0; i < l; i++) {
          let coordinate = { a: [], b: [], c: [], d: [] },
            str = "";
          if (i == 0) {
            coordinate.a[0] = xScale(xDomain[0]);
            coordinate.a[1] = yRange[0];

            coordinate.b[0] = xScale(arr[0]) - space - angle;
            coordinate.b[1] = yRange[0];

            coordinate.c[0] = xScale(arr[0]) - space;
            coordinate.c[1] = marginTop;

            coordinate.d[0] = xScale(xDomain[0]);
            coordinate.d[1] = marginTop;
          } else if (i == l - 1) {
            coordinate.a[0] = xScale(sumArr(arr, i - 1)) + space - angle;
            coordinate.a[1] = yRange[0];

            coordinate.b[0] = xScale(sumArr(arr));
            coordinate.b[1] = yRange[0];

            coordinate.c[0] = xScale(sumArr(arr));
            coordinate.c[1] = marginTop;

            coordinate.d[0] = xScale(sumArr(arr, i - 1)) + space;
            coordinate.d[1] = marginTop;
          } else {
            coordinate.a[0] = xScale(sumArr(arr, i - 1)) - angle;
            coordinate.a[1] = yRange[0];

            coordinate.b[0] = xScale(sumArr(arr, i)) - angle;
            coordinate.b[1] = yRange[0];

            coordinate.c[0] = xScale(sumArr(arr, i));
            coordinate.c[1] = marginTop;

            coordinate.d[0] = xScale(sumArr(arr, i - 1));
            coordinate.d[1] = marginTop;
          }
          that.finalPoints.push(coordinate);
          for (const key in coordinate) {
            let value = coordinate[key].join(",");
            str += value + (key == "d" ? "" : " ");
          }
          list.push(str);
        }
        // console.log("list", list);
        return list;
      }

      function getInitialPoints(arr) {
        //获取初始点
        const list = [];
        const l = arr.length;
        const isOdd = l % 2 == 1 ? true : false; //长度是否是奇数
        space = l > 3 ? 0 : space;
        let coordinate = {};
        if (effect == "bothIn") {
          for (let i = 0; i < l; i++) {
            let coordinate = { a: [], b: [], c: [], d: [] },
              str = "";
            if (i == 0) {
              coordinate.a[0] = xScale(xDomain[0]);
              coordinate.a[1] = yRange[0];

              coordinate.b[0] = xScale(xDomain[0]);
              coordinate.b[1] = yRange[0];

              coordinate.c[0] = xScale(xDomain[0]);
              coordinate.c[1] = marginTop;

              coordinate.d[0] = xScale(xDomain[0]);
              coordinate.d[1] = marginTop;
            } else if (i == l - 1) {
              coordinate.a[0] = xScale(sumArr(arr));
              coordinate.a[1] = yRange[0];

              coordinate.b[0] = xScale(sumArr(arr));
              coordinate.b[1] = yRange[0];

              coordinate.c[0] = xScale(sumArr(arr));
              coordinate.c[1] = marginTop;

              coordinate.d[0] = xScale(sumArr(arr));
              coordinate.d[1] = marginTop;
            } else {
              if (isOdd && i == (l - 1) / 2) {
                coordinate.a[0] = xScale(sumArr(arr, i - 1)) - angle;
                coordinate.a[1] = yRange[0] - height;

                coordinate.b[0] = xScale(sumArr(arr, i)) - angle;
                coordinate.b[1] = yRange[0] - height;

                coordinate.c[0] = xScale(sumArr(arr, i));
                coordinate.c[1] = marginTop - height;

                coordinate.d[0] = xScale(sumArr(arr, i - 1));
                coordinate.d[1] = marginTop - height;
              } else {
                if (i < l / 2) {
                  // const num = i;
                  coordinate.a[0] = that.finalPoints[i - 1].b[0];
                  coordinate.a[1] = yRange[0];

                  coordinate.b[0] = that.finalPoints[i - 1].b[0];
                  coordinate.b[1] = yRange[0];

                  coordinate.c[0] = that.finalPoints[i - 1].c[0];
                  coordinate.c[1] = marginTop;

                  coordinate.d[0] = that.finalPoints[i - 1].c[0];
                  coordinate.d[1] = marginTop;
                } else {
                  coordinate.a[0] = that.finalPoints[i + 1].a[0];
                  coordinate.a[1] = yRange[0];

                  coordinate.b[0] = that.finalPoints[i + 1].a[0];
                  coordinate.b[1] = yRange[0];

                  coordinate.c[0] = that.finalPoints[i + 1].d[0];
                  coordinate.c[1] = marginTop;

                  coordinate.d[0] = that.finalPoints[i + 1].d[0];
                  coordinate.d[1] = marginTop;
                }
              }
            }
            that.initialPoints.push(coordinate);
            for (const key in coordinate) {
              let value = coordinate[key].join(",");
              str += value + (key == "d" ? "" : " ");
            }
            list.push(str);
          }
        } else if (effect == "leftIn") {
          // console.log("finalPoints", that.finalPoints);
          for (let i = 0; i < l; i++) {
            let coordinate = { a: [], b: [], c: [], d: [] },
              str = "";
            coordinate.a[0] = that.finalPoints[i].a[0];
            coordinate.a[1] = that.finalPoints[i].a[1];
            coordinate.b = coordinate.a;

            coordinate.d[0] = that.finalPoints[i].d[0];
            coordinate.d[1] = that.finalPoints[i].d[1];
            coordinate.c = coordinate.d;

            that.initialPoints.push(coordinate);
            for (const key in coordinate) {
              let value = coordinate[key].join(",");
              str += value + (key == "d" ? "" : " ");
            }
            list.push(str);
          }
        } else {
          for (let i = 0; i < l; i++) {
            let coordinate = { a: [], b: [], c: [], d: [] },
              str = "";
            coordinate.a[0] = that.finalPoints[i].a[0];
            coordinate.a[1] = that.finalPoints[i].a[1];
            coordinate.b = coordinate.a;

            coordinate.d[0] = that.finalPoints[i].d[0];
            coordinate.d[1] = that.finalPoints[i].d[1];
            coordinate.c = coordinate.d;

            that.initialPoints.push(coordinate);
            for (const key in coordinate) {
              let value = coordinate[key].join(",");
              str += value + (key == "d" ? "" : " ");
            }
            list.push(str);
          }
        }
        return list;
      }

      function sumArr(arr, index) {
        let sum = 0;
        let num = index == undefined ? arr.length - 1 : index;
        for (let i = 0; i < arr.length; i++) {
          if (i <= num) {
            sum += arr[i];
          } else {
            sum += 0;
          }
        }
        return sum;
      }
    },
  },
  mounted() {
    this.init();
  },
};
</script>
<style lang="less" scoped>
// @import "./index.less";
</style>
